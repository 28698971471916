import Config from '@/api/config.js'

const state = {
    competitions: [],
    predictionsOpen: false,
    currentGameweek: -1,
    standings: []
};

const getters = {
    getCompetitions: state => state.competitions,
    getCompetitionPredictionsOpen: state => state.predictionsOpen,
    getCurrentGameweek: state => state.currentGameweek,
    getCurrentStandings: state => state.standings,
    getMunsterRoundRobin: state => { return state.standings.filter(it => it.groupEnt.id == 1) }, // TODO - hardcoded getters
    getLeinsterRoundRobin: state => { return state.standings.filter(it => it.groupEnt.id == 2) },
}

const mutations = {
    setCompetitions(state, competitionData){
        state.competitions = competitionData;
    },
    setCompetitionPredictionsOpen(state, open){
        state.predictionsOpen = open;
    },
    setCurrentGameweekForCompetition(state, gameweek){
      state.currentGameweek = gameweek;
    },
    setCompetitionStandings(state, standings){
      state.standings = standings;
    }
};

const actions = {
  async updateCompetition({ commit }, competitionData) {
    try {
      for(const matchday of competitionData.matchdays){
        let combinedDateTime = matchday.startDateString + 'T' + matchday.startTimeString + ':00';
        matchday.startDate = combinedDateTime; 
        let combinedEndDateTime = matchday.endDateString + 'T' + matchday.endTimeString + ':00';
        matchday.endDate = combinedEndDateTime; 
      }
      const response = await Config.updateCompetition(competitionData);
      console.log(response);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error updating competition:', error);
    }
  },
  async fetchCompetitions({ commit }) {
    try {
      const response = await Config.fetchCompetitions(); // Make API call
      commit('setCompetitions', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchCompetitionPredictionOpen({ commit }, competitionId) {
    try {
      const response = await Config.fetchCompetitionPredictionsOpen(competitionId); // Make API call
      commit('setCompetitionPredictionsOpen', response.data);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error creating competition:', error);
    }
  },
  async fetchCurrentGameweekForCompetition({ commit }, competitionId) {
    try {
      const response = await Config.fetchCurrentGameweekForCompetition(competitionId);
      commit('setCurrentGameweekForCompetition', response.data);
    } catch (error) {
      console.error('Error fetching current gameweek:', error);
    }
  },
  async fetchCompetitionStandings({ commit }, competitionId) {
    try {
      const response = await Config.fetchCompetitionStandings(competitionId);
      commit('setCompetitionStandings', response.data);
    } catch (error) {
      console.error('Error fetching current standings:', error);
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
