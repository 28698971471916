import ENDPOINTS from '@/api/endpoints/ktg-services.js'
import { service } from '@/api/baseClient.js'

export default {
    login (user) {
        return service.post(ENDPOINTS.AUTH.LOGIN, user)
    },
    forgotPassword (email) {
        return service.post(ENDPOINTS.AUTH.FORGOT_PASSWORD, null, {
            params: {
                email: email,
            }
        })
    },
    changePassword (details) {
        return service.post(ENDPOINTS.AUTH.CHANGE_PASSWORD, details)
    },
    resetPassword (details) {
        return service.post(ENDPOINTS.AUTH.RESET_PASSWORD, details)
    },
    signup (userInfo) {
        return service.post(ENDPOINTS.AUTH.SIGN_UP, userInfo)
    },
    updateDisplayName (displayNameDetails) {
        return service.post(ENDPOINTS.AUTH.UPDATE_DISPLAYNAME, displayNameDetails)
    }
}
