import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'
import LeagueMain from '@/views/app/leagues/LeagueMain'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/app/Index'),
      // beforeEnter: authenticate,
      redirect: '/app/hurling/home',

      children: [
        {
          path: "/config/",
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: 'team',
              component: () => import("@/views/config/setup/TeamManagement")
            },
            {
              path: 'competition',
              component: () => import("@/views/config/setup/CompetitionManagement")
            },
            {
              path: 'fixture',
              component: () => import("@/views/config/setup/FixtureManagement")
            }
          ]
        },
        {
          path: "/game/",
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: 'fixture',
              component: () => import("@/views/config/game/FixtureResults")
            }
          ]
        },
        {
          path: '/app/',
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: "hurling/",
              component: () => import('@/views/app/pages/Pages'),
              children: [
                {
                  path: 'home',
                  component: () => import('@/views/app/pages/Home'),
                  meta: { sport: "hurling", label: 'Home' },
                },
                {
                  path: 'selection/current',
                  component: () => import('@/views/app/pages/Selection'),
                  beforeEnter: authenticate,
                  meta: { sport: "hurling", label: 'Current Gameweek', requiresAuth: true },
                },
                {
                  path: 'selection/history',
                  component: () => import('@/views/app/selections/History'),
                  beforeEnter: authenticate,
                  meta: { sport: "hurling", label: 'Selection History', requiresAuth: true },
                },
                {
                  path: 'selection/search',
                  component: () => import('@/views/app/selections/PlayerSearch'),
                  beforeEnter: authenticate,
                  meta: { sport: "hurling", label: 'Player Search', requiresAuth: true },
                },
                {
                  path: 'leagues/search',
                  name: 'league',
                  props: true,
                  component: () => import('@/views/app/leagues/LeagueSearch'),
                  meta: { sport: "hurling", label: 'League Search' },
                },
                {
                  path: 'leagues/private/:mode?',
                  name: 'league',
                  props: true,
                  component: () => import('@/views/app/leagues/LeagueAdmin'),
                  meta: { sport: "hurling", label: 'Private Leagues' },
                },
                {
                  path: 'leagues/:leagueId?',
                  name: 'league',
                  props: true,
                  component: LeagueMain,
                  meta: { sport: "hurling", label: 'League' },
                },
                {
                  path: 'matches/fixtures',
                  component: () => import('@/views/app/fixtures/FixturesOverview'),
                  meta: { sport: "hurling", label: 'Fixtures', type: 'fixtures' },
                },
                {
                  path: 'matches/results',
                  component: () => import('@/views/app/fixtures/FixturesOverview'),
                  meta: { sport: "hurling", label: 'Results', type: 'results' },
                },
                {
                  path: 'standings/:stage?',
                  props: true,
                  component: () => import('@/views/app/standings/Standings'),
                  meta: { sport: "hurling", label: 'Standings' },
                },
                {
                  path: 'permutations/:stage?',
                  props: true,
                  component: () => import('@/views/app/standings/Permutations'),
                  meta: { sport: "hurling", label: 'Permutations' },
                },
                {
                  path: 'statistics/profiles',
                  component: () => import('@/views/app/profiles/ProfileList'),
                  meta: { sport: "hurling", label: 'Team Profiles' },
                },
                {
                  path: 'statistics/profile/:teamId?',
                  component: () => import('@/views/app/profiles/Profile'),
                  meta: { sport: "hurling", label: 'Team Profile' },
                },
                {
                  path: 'statistics/team',
                  component: () => import('@/views/app/statistics/StatisticsHurling'),
                  meta: { sport: "hurling", label: 'Team Records' },
                },
                {
                  path: 'statistics/ranking',
                  component: () => import('@/views/app/statistics/RankingsHurling'),
                  meta: { sport: "hurling", label: 'Team Ranking' },
                },
                {
                  path: 'statistics/main',
                  component: () => import('@/views/app/statistics/StatisticsMain'),
                  meta: { sport: "hurling", label: 'Statistics' },
                },
                {
                  path: 'statistics/honours',
                  component: () => import('@/views/app/statistics/HonoursHurling'),
                  meta: { sport: "hurling", label: 'Honours' },
                },
              ]
            },
            {
              path: "football/",
              component: () => import('@/views/app/pages/Pages'),
              children: [
                {
                  path: 'home',
                  component: () => import('@/views/app/pages/Home'),
                  meta: { sport: "football", label: 'Home' },
                },
                {
                  path: 'selection/current',
                  component: () => import('@/views/app/pages/Selection'),
                  beforeEnter: authenticate,
                  meta: { sport: "football", label: 'Current Gameweek', requiresAuth: true },
                },
                {
                  path: 'selection/history',
                  component: () => import('@/views/app/selections/History'),
                  beforeEnter: authenticate,
                  meta: { sport: "football", label: 'Selection History', requiresAuth: true },
                },
                {
                  path: 'leagues/:leagueId?',
                  name: 'league',
                  props: true,
                  component: LeagueMain,
                  meta: { sport: "football", label: 'League' },
                },
                {
                  path: 'matches/fixtures',
                  component: () => import('@/views/app/fixtures/FixturesOverview'),
                  meta: { sport: "football", label: 'Fixtures', type: 'fixtures' },
                },
                {
                  path: 'matches/results',
                  component: () => import('@/views/app/fixtures/FixturesOverview'),
                  meta: { sport: "football", label: 'Results', type: 'results' },
                },
                {
                  path: 'standings/:stage?',
                  props: true,
                  component: () => import('@/views/app/standings/Standings'),
                  meta: { sport: "football", label: 'Standings' },
                },
                {
                  path: 'statistics/team',
                  component: () => import('@/views/app/statistics/StatisticsFootball'),
                  meta: { sport: "football", label: 'Team Records' },
                },
                {
                  path: 'statistics/honours',
                  component: () => import('@/views/app/statistics/HonoursFootball'),
                  meta: { sport: "football", label: 'Honours' },
                },
                {
                  path: 'statistics/ranking',
                  component: () => import('@/views/app/statistics/RankingsHurling'),
                  meta: { sport: "football", label: 'Team Ranking' },
                }
              ]
            }
          ]
        },
        {
          path: '/help/',
          component: () => import('@/views/app/pages/Pages'),
          children: [
            {
              path: 'faq/:page?',
              props: true,
              component: () => import('@/views/app/help/FAQ'),
              meta: { label: 'FAQ' },
            },
            {
              path: 'legal',
              component: () => import('@/views/app/help/Legal'),
              meta: { label: 'Legal' },
            },
            {
              path: 'about',
              component: () => import('@/views/app/help/About'),
              meta: { label: 'About us'}
            }
          ]
        },
      ],
    },
    {
      path: '/app/sessions',
      component: () => import('@/views/app/sessions/Sessions'),

      children: [
        {
          path: 'error',
          component: () => import('@/views/app/sessions/Error'),
        },
        {
          path: 'forgot',
          component: () => import('@/views/app/sessions/Forgot'),
        },
        {
          path: 'reset-password',
          component: () => import('@/views/app/sessions/Reset'),
        },
        {
          path: 'lockscreen',
          component: () => import('@/views/app/sessions/Lockscreen'),
        },
        {
          path: 'sign-in',
          name: 'login',
          component: () => import('@/views/app/sessions/SignIn'),
        },
        {
          path: 'sign-up',
          component: () => import('@/views/app/sessions/SignUp'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
})

export default router
