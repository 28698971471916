import ENDPOINTS from '@/api/endpoints/ktg-services.js'
import { service } from '@/api/baseClient.js'


export default { 
    createTeam(teamData){
        return service.post(ENDPOINTS.TEAM.CREATE, teamData)
    },
    updateCompetition(competitionData){
        return service.post(ENDPOINTS.COMPETITION.UPDATE, competitionData)
    },
    createFixture(fixtureData){
        return service.post(ENDPOINTS.FIXTURE.CREATE, fixtureData)
    },
    endGameWeek(gameWeekData){
        return service.post(ENDPOINTS.GAME.END_WEEK, gameWeekData)
    },
    confirmSelection(selectionData){
        return service.post(ENDPOINTS.SELECTION.CONFIRM, selectionData)
    },
    fetchWinningMargins(sport){
        return service.get(ENDPOINTS.GAME.WINNING_MARGINS, {
            params: {
                sport: sport,
            }
        });
    },
    fetchGameWeekSelection(userId, gameweek, competitionId){
        return service.get(ENDPOINTS.GAME.PREDICTIONS, {
            params: {
                userId: userId,
                matchday: gameweek,
                competitionId: competitionId
            }
        });
    },
    fetchGameweekResultForUser(userId, sport, gameweek){
        return service.get(ENDPOINTS.GAME.GAMEWEEK_RESULT, {
            params: {
                userId: userId,
                sport: sport,
                gameweek: gameweek
            }
        });
    },
    fetchPastSelections(userId, competitionId){
        return service.get(ENDPOINTS.GAME.PAST_PREDICTIONS, {
            params: {
                userId: userId,
                competitionId: competitionId
            }
        });
    },
    fetchCompetitions(){
        return service.get(ENDPOINTS.COMPETITION.LIST);
    },
    fetchCompetitionStandings(competitionId){
        return service.get(ENDPOINTS.COMPETITION.STANDINGS, {
            params: {
                competitionId: competitionId
            }
        });
    },
    fetchClubs(){
        return service.get(ENDPOINTS.CLUB.LIST);
    },
    searchClubs(searchString){
        return service.get(ENDPOINTS.CLUB.SEARCH, {
            params: {
                searchString: searchString
            }
        })
    },
    searchClubsByCounty(searchString, county){
        return service.get(ENDPOINTS.CLUB.SEARCH_BY_COUNTY, {
            params: {
                searchString: searchString,
                county: county
            }
        })
    },
    updateClub (clubDetails) {
        return service.post(ENDPOINTS.CLUB.UPDATE_USERS_CLUB, clubDetails)
    },
    findRankingsBySport(sport) {
        if(sport == 'HURLING'){
            return service.get(ENDPOINTS.RANKINGS.HURLING)
        }
    },
    fetchCurrentGameweekForCompetition(id){
        return service.get(ENDPOINTS.COMPETITION.CURRENT_GAMEWEEK, {
            params: {
                competitionId: id
            }
        });
    },
    fetchCompetitionPredictionsOpen(competitionId){
        return service.get(ENDPOINTS.COMPETITION.PREDICTIONS_OPEN, {
            params: {
                competitionId: competitionId
            }
        })
    },
    setCompetitionPredictionOpen(open){
        return service.post(ENDPOINTS.COMPETITION.PREDICTIONS_OPEN, null, {
            params: {
              open: open
            }
        })
    },
    fetchTeams(sport){
        return service.get(ENDPOINTS.TEAM.LIST, {
            params: {
                sport: sport,
            },
        })
    },
    fetchTeamsByCompetition(competitionId){
        return service.get(ENDPOINTS.TEAM.LIST_BY_COMPETITION, {
            params: {
                competitionId: competitionId,
            },
        })
    },
    fetchTeamsBySport(sport){
        return service.get(ENDPOINTS.RESULT.LIST_TEAMS, {
            params: {
                sport: sport,
            },
        })
    },
    fetchFixtures(competitionId, snapshot){
        return service.get(ENDPOINTS.FIXTURE.LIST, {
            params: {
                competitionId: competitionId,
                snapshot: snapshot
            },
        })
    },
    updateFixture(fixtureData){
        return service.post(ENDPOINTS.FIXTURE.UPDATE, fixtureData)
    },
    fetchInPlayFixtures(competitionId){
        return service.get(ENDPOINTS.FIXTURE.IN_PLAY, {
            params: {
                competitionId: competitionId
            },
        })
    },
    fetchCurrentFixtures(competitionId){
        return service.get(ENDPOINTS.FIXTURE.CURRENT, {
            params: {
                competitionId: competitionId
            },
        })
    },
    fetchResultsByTeamAndCompetitionId(teamId, competitionId){
        return service.get(ENDPOINTS.RESULT.OVERALL, {
            params: {
                teamId: teamId,
                competitionId: competitionId
            }
        })
    },
    fetchResultsByTeamsAndCompetitionId(team1Id, team2Id, competitionId){
        return service.get(ENDPOINTS.RESULT.HEAD_TO_HEAD, {
            params: {
                team1Id: team1Id,
                team2Id: team2Id,
                competitionId: competitionId
            }
        })
    },
    fetchPersonalLeagues(userId){
        return service.get(ENDPOINTS.LEAGUE.PERSONALLIST, {
            params: {
                userId: userId,
            },
        })
    },
    createPrivateLeague (leagueDetails) {
        return service.post(ENDPOINTS.LEAGUE.CREATE_PRIVATE, leagueDetails)
    },
    registerUserInLeague (registrationDetails) {
        return service.post(ENDPOINTS.LEAGUE.REGISTER_USER, registrationDetails)
    },
    fetchParticipantsForLeague(leagueId){
        return service.get(ENDPOINTS.LEAGUE.PARTICIPANTS, {
            params: {
                leagueId: leagueId,
            },
        })
    },
    fetchLeagueState(leagueId){
        return service.get(ENDPOINTS.LEAGUE.STATE, {
            params: {
                leagueId: leagueId,
            },
        })
    },
    fetchLeagueStateForGameweek(leagueId, gameweek){
        return service.get(ENDPOINTS.LEAGUE.GAMEWEEK, {
            params: {
                leagueId: leagueId,
                gameweek: gameweek
            },
        })
    },
    fetchLeaguesSnapshot(userId){
        return service.get(ENDPOINTS.LEAGUE.SNAPSHOT, {
            params: {
                userId: userId,
            },
        })
    },
    fetchGameweekSnapshot(userId, gameweek){
        return service.get(ENDPOINTS.LEAGUE.GAMEWEEK_SNAPSHOT, {
            params: {
                userId: userId,
                gameweek: gameweek
            },
        })
    },
    fetchTeamTotals(year){
        return service.get(ENDPOINTS.STATISTICS.TEAM_TOTAL_SCORE, {
            params: {
                year: year
            }
        })
    },
    fetchPlayerTotals(team, year){
        return service.get(ENDPOINTS.STATISTICS.PLAYER_TOTAL_SCORE, {
            params: {
                team: team,
                year: year
            }
        })
    },
    fetchTeam(team){
        return service.get(ENDPOINTS.STATISTICS.TEAM_SCORERS, {
            params: {
                team: team
            }
        })
    },
    searchByUsername(searchString){
        return service.get(ENDPOINTS.USERS.SEARCH_BY_USERNAME, {
            params: {
                searchString: searchString
            }
        })
    },
    searchByLeagueName(searchString){
        return service.get(ENDPOINTS.LEAGUE.SEARCH_BY_LEAGUE_NAME, {
            params: {
                searchString: searchString
            }
        })
    },
    existsByLeagueName(name){
        return service.get(ENDPOINTS.LEAGUE.EXISTS_BY_LEAGUE_NAME, {
            params: {
                name: name
            }
        })
    },
    findWinnersByCompetition(sport){
        return service.get(ENDPOINTS.WINNERS.LIST, {
            params: {
                sport: sport
            }
        })
    }
}