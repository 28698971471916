import Vuex from 'vuex'
import Vue from 'vue'
import themeConfig from './modules/themeConfig'
import authData from './modules/authData'
import team from './modules/team'
import competition from './modules/competition'
import fixture from './modules/fixture'
import leagues from './modules/leagues'
import selection from './modules/selection'
import score from './modules/score'
import users from './modules/users'
import result from './modules/result'
import clubs from './modules/clubs'
import winners from './modules/winners'

import auth from './modules/auth'
import ranking from './modules/ranking'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    authData,
    auth,
    team,
    competition,
    fixture,
    leagues,
    selection,
    score,
    result,
    ranking,
    users,
    clubs,
    winners
  },
})
