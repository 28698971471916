import Config from '@/api/config.js'

const state = {
    clubs: [],
    userClub: -1
};

const getters = {
    getClubs: state => { return state.clubs },
    getUserClub: state => state.userClub
}

const mutations = {
    setClubs(state, clubData){
        state.clubs = clubData;
    },
    setUserClub(state, clubId){
      state.userClub = clubId;
    }
};

const actions = {
  async fetchClubs({ commit }) {
    try {
      const response = await Config.fetchClubs(); // Make API call
      commit('setClubs', response.data.content);
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error fetching clubs:', error);
    }
  },
 async searchClubs({ commit }, search) {
    try {
      const response = await Config.searchClubs(search);
      commit('setClubs', response.data.content);
    } catch (error) {
      console.error('Error fetching clubs: ', error)
    }
  },
  async searchClubsByCounty({ commit }, searchData) {
    try {
      const response = await Config.searchClubsByCounty(searchData.searchString, searchData.county);
      commit('setClubs', response.data.content);
    } catch (error) {
      console.error('Error fetching clubs: ', error)
    }
  },
  async updateClub ({ commit }, updateDetails) {
    let response = await Config.updateClub(updateDetails);
    if(response.data == -1){
        //commit('updateErrorMessage', "Display name taken");
    } else {
        commit('setUserClub', response.data);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
