import Config from '@/api/config.js'

const state = {
    usersSearch: []
};

const getters = {
    getSearchedUsers: state => { return state.usersSearch.map(user => ({
      text: user.displayName ? user.displayName : user.firstName + " " + user.lastName,
      value: user.id
    })); }
}

const mutations = {
    updateUsers(state, userData){
        state.usersSearch = userData;
    }
};

const actions = {
  async searchByUsername({ commit }, searchString) {
    try {
      const response = await Config.searchByUsername(searchString);
      commit('updateUsers', response.data);
      return true;
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error searching for users:', error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
